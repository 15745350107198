//export const CONST_APIURL = "https://api.merise.in";
//export const CONST_APIURL = "http://localhost:3000";

const CONST_APIURL = process.env.NEXT_PUBLIC_BASE_URL
export const CONST_FEATURED = CONST_APIURL + "/api/v1/featuredproduct";
export const CONST_TRENDING = CONST_APIURL + "/api/v1/trending";
export const CONST_BOD = CONST_APIURL + "/api/v1/brandofdesign";
export const CONST_BANNER = CONST_APIURL + "/api/v1/banner";
export const CONST_PRODUCTCATEGORY_BANNER =
  CONST_APIURL + "/api/v1/productcategorybanner";
export const CONST_PRODUCTS_BY_BRAND =
  CONST_APIURL + "/api/v1/productsbybrand?brand=";
export const CONST_FILTER_CATEGORY = CONST_APIURL + "/api/v1/filter?category=";
export const CONST_FILTER_TYPE = CONST_APIURL + "/api/v2/filter?type=";
export const CONST_CATEGORY_FTR =
  CONST_APIURL + "/api/v1/categoryfilter?category=";
export const CONST_PRODUCTDETAIL = CONST_APIURL + "/api/v1/productdetail?url=";
export const CONST_DEVICEBRANDCT = CONST_APIURL + "/api/v1/devicebydesign";
export const CONST_PRODUCT_LIST = CONST_APIURL + "/api/v1/product?category=";
export const CONST_PRODUCT_LIST_BY_TYPE =
  CONST_APIURL + "/api/v2/productbytype?type=";

export const CONST_PAYMENT = CONST_APIURL + "/api/v1/payment";
export const CONST_PAYMENT_STATUS = CONST_APIURL + "/api/v1/paymentstatus";
export const CONST_PAYMENT_CALLBACK = CONST_APIURL + "/api/v1/paymentcallback";
export const CONST_PINCODE = CONST_APIURL + "/api/v1/pincode";
export const CONST_COUNTRY = CONST_APIURL + "/api/v1/country";
export const CONST_STATE = CONST_APIURL + "/api/v1/state";
export const CONST_ORDER = CONST_APIURL + "/api/v1/order";
export const CONST_ADDRESS = CONST_APIURL + "/api/v1/address";

export const CONST_NOTIFICATION = CONST_APIURL + "/api/v1/notification";

export const CONST_DEVICE_BRAND = CONST_APIURL + "/api/v1/devicebrand";
export const CONST_DEVICE = CONST_APIURL + "/api/v1/device";
export const CONST_DEVICE_MODEL = CONST_APIURL + "/api/v1/devicemodel";

export const CONST_DEVICE_LIST = CONST_APIURL + "/api/v1/devicelist";

export const CONST_CART = CONST_APIURL + "/api/v1/cart";
export const CONST_WISHLIST_ADD = CONST_APIURL + "/api/v1/account/wishlist/add";
export const CONST_CONTACTUS = CONST_APIURL + "/api/v1/contactus";

export const CONST_PRODUCT_OPTION = CONST_APIURL + "/api/v1/productoption";
export const CONST_RELATED_PRODUCT =
  CONST_APIURL + "/api/v1/relatedproduct?type=";
export const CONST_MIN_PRICE = 0;
export const CONST_MAX_PRICE = 2500;
export const CONST_LOCAL_TAT = 2;
export const CONST_INTERNATIONAL_TAT = 10;
export const CONST_BLOG = CONST_APIURL + "/api/v1/blog";
export const CONST_BLOGDETAIL = CONST_APIURL + "/api/v1/blog?title=";
export const CONST_SIMILAR_PRODUCTS =
  CONST_APIURL + "/api/v1/similardesignproduct";
export const CONST_FORGOT_PASS =
  CONST_APIURL + "/api/v1/account/forgot/send-email";
export const CONST_RESET_PASS = CONST_APIURL + "/api/v1/account/forgot/reset";
export const CONST_SIGNUP = CONST_APIURL + "/api/v3/new/signup/generate";
export const CONST_SIGNUP_VALIDATE = CONST_APIURL + "/api/v3/new/login/validate";
export const CONST_LOGIN = CONST_APIURL + "/api/v1/account/login";
export const CONST_AUTH = CONST_APIURL + "/api/v1/account/oauth";
export const CONST_OTP = CONST_APIURL + "/api/v3/new/login/generate/phone";
export const CONST_VALIDATE_OTP = CONST_APIURL + "/api/v3/new/login/validate";
export const CONST_MENU = CONST_APIURL + "/api/v1/menu";
export const CONST_PAYMENT_OPTION = CONST_APIURL + "/api/v1/paymentoption";
export const CONST_SHIPPING_CHARGE = CONST_APIURL + "/api/v1/shippingamount";
export const CONST_SHIPPING_OPTION = CONST_APIURL + "/api/v1/shippingoption";
export const CONST_EXPRESS_SHIPPING_OPTION = CONST_APIURL + "/api/v2/expressoption";
export const CONST_DETAILS_CUSTOMER = CONST_APIURL + "/api/v1/account/details";
export const CONST_ORDER_DETAILS =
  CONST_APIURL + "/api/v1/account/myorders/list";
export const CONST_WISHLIST =
  CONST_APIURL + "/api/v1/account/wishlist/list";
export const CONST_WISHLIST_DEL =
  CONST_APIURL + "/api/v1/account/wishlist/del";
export const CONST_WISHLIST_GET =
  CONST_APIURL + "/api/v1/account/wishlist/get";
export const CONST_SINGLE_ORDER_DETAILS =
  CONST_APIURL + "/api/v1/account/myorders/details";
export const CONST_CHANGEPASSWORD =
  CONST_APIURL + "/api/v1/account/updatepassword";
export const CONST_PAYPAL_PAYMENT_STATUS =
  CONST_APIURL + "/api/v1/payment-status";
export const CONST_SEARCH =
  CONST_APIURL + "/api/v1/search/option";
export const CONST_SEARCH_PRODUCT =
  CONST_APIURL + "/api/v1/search/product";
export const CONST_NEWSLETTER = CONST_APIURL + "/api/v1/newsletter";
export const CONST_DISCOUNT_VALID =
  CONST_APIURL + "/api/v1/discount/validate";

  export const CONST_DISCOUNT_VALID_V3 =
  CONST_APIURL + "/api/v3/discount/validate";

export const CONST_GIFTCARD_VALID =
  CONST_APIURL + "/api/v1/giftcard/validate";
export const CONST_DISCOUNT_REMOVE =
  CONST_APIURL + "/api/v1/discount/remove";
export const CONST_GIFTCARD_REMOVE =
  CONST_APIURL + "/api/v1/giftcard/remove";
export const CONST_COLLECTION =
  CONST_APIURL + "/api/v1/collection";
export const CONST_NEWURL =
  CONST_APIURL + "/api/v1/newurl";
export const CONST_SITEMAP =
  CONST_APIURL + "/api/v1/sitemap";

export const CONST_COLLECTION_PRODUCT =
  CONST_APIURL + "/api/v2/collection/products";
export const CONST_COLLECTION_FILTER =
  CONST_APIURL + "/api/v3/collection/filter";
export const CONST_COLLECTION_V2 =
  CONST_APIURL + "/api/v2/collection";

export const CONST_IP_DETAILS =
  CONST_APIURL + "/api/v1/ip";


export const CONST_DEALS_LIST =
  CONST_APIURL + "/api/v1/deals/list";
export const CONST_DEALS_BESTSELLER =
  CONST_APIURL + "/api/v1/deals/bestseller";
export const CONST_DEALS_PRODUCT_TYPE =
  CONST_APIURL + "/api/v1/deals/producttype";

export const CONST_FILTER_TYPE_V3 = CONST_APIURL + "/api/v3/filter?type=";
export const CONST_FILTER_TYPE_ALL = CONST_APIURL + "/api/v3/filter?type=";
export const CONST_PRODUCT_LIST_BY_TYPE_V3 =
  CONST_APIURL + "/api/v3/productbytype?type=";
export const CONST_CATEGORY_FTR_V3 =
  CONST_APIURL + "/api/v3/categoryfilter?category=";
export const CONST_TRACK_ORDER = CONST_APIURL + "/api/v1/trackorder";
export const CONST_AI_ITEM_ADD_CART = CONST_APIURL + "/api/v3/cart";

export const CONST_PROXY_IMAGE_URL = CONST_APIURL + "/api/v3/image?slug=";
export const CONST_NEW_LOGIN_API = CONST_APIURL + "/api/v3/new/login/generate/email";
export const CONST_EVENT_API = CONST_APIURL + "/api/v3/event";

export const CONST_IMAGE_CHECKER = CONST_APIURL + "/api/v1/imagechecker";
export const CONST_GIFTCARD_DETAILS = CONST_APIURL + "/api/v1/giftcard/details";
export const CONST_GIFTCARD_LIST = CONST_APIURL + "/api/v1/account/giftcards"
export const CONST_GA_EVENT_API = CONST_APIURL + "/api/v3/ga4";

export const CONST_UPDATE_ACCOUNT_GENERATE_OTP = CONST_APIURL + "/api/v3/new/update/generate";
export const CONST_UPDATE_NAME = CONST_APIURL + "/api/v3/new/update/name";
export const CONST_UPDATE_ACCOUNT_VALIDATE_OTP = CONST_APIURL + "/api/v3/new/update/validate";
export const CONST_NEW_ACCOUNT_CREATE = CONST_APIURL + "/api/v3/new/login/create";

export const CONST_DEVICE_DETAIL = CONST_APIURL + "/api/v1/devicedata/detail";
export const CONST_DEVICE_DETAIL_BESTSELLER = CONST_APIURL + "/api/v1/devicedata/bestseller";
export const CONST_DEVICE_DETAIL_BLOG = CONST_APIURL + "/api/v1/devicedata/blogs";

export const CONST_RAZOR_PAY_ORDER_API = CONST_APIURL + "/api/v3/razorpay/payment"
export const CONST_RAZOR_PAY_CALLBACK = CONST_APIURL + "/api/v3/razorpay/callback"
export const CONST_RAZOR_PAY_STATUS = CONST_APIURL + "/api/v3/razorpay/status"
